/*首屏*/
.cs-top-box { background-image: url(../../../public/img/cs/bg.jpg); background-repeat: no-repeat; background-size: cover; background-position: center; }
.cs-top-box-title h2 { color: #EF233C; padding-top: 5%; text-align: center; letter-spacing: 0.2em; font-size: 2.6em; font-weight: bold; white-space: nowrap; overflow-x: auto;  text-shadow: 1px 1px 2px #2c2c2c;  }
.cs-top-form-title p { text-align: center; margin: 5% auto 1%; color: #FF7400; font-size: 1.8em; letter-spacing: 0.5rem; }
.cs-top-form { /* display: block; */ margin: 0 auto; text-align: center; }
.cs-top-form .ant-form-item-explain-error{ text-align: center; }
.cs-top-form-text { display: inline-block; }
.cs-top-form-text>select { padding: 8px; border: 1px solid #f2f2f2; float: left; height: 40px; box-sizing: border-box; border-top-left-radius: 4px; border-bottom-left-radius: 4px; }
.cs-top-form-text>div { display: inline-block; }
.cs-top-form-text>div>i { position: absolute; margin-left: 4px; color: #aaaaaa; line-height: 40px; }
.cs-top-form-text>div>input { padding: 8px 8px 8px 25px; height: 40px; box-sizing: border-box; border: 1px solid #f2f2f2; min-width: 300px; max-width: 370px; }
.cs-top-form-text>input { float: left; color: white; background-color: #EF233C; height: 40px; padding: 0 18px; border: 1px solid #f2f2f2; margin-left: -1px; border-top-right-radius: 4px; border-bottom-right-radius: 4px; cursor: pointer; }
.cs-top-form-more { margin-bottom: 8%; text-align: center;}
.cs-top-form-more p { text-align: center; margin-top: 16px; }

/*精选服务*/
.cs-services-box { margin-top: 25px; }
.cs-services-content>div { box-sizing: border-box; }
.cs-services-content>div>div { text-align: center; display: block; margin: 8px auto; padding: 16px; background-color: #edf2f4; overflow-x: auto; }
.cs-services-content>div>div::-webkit-scrollbar { display: none; }
.cs-services-content>div>div>h3 { font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑', sans-serif; font-weight: bold; white-space: nowrap; }
.cs-services-content p { line-height: 36px; white-space: nowrap; margin-bottom: unset; }
.cs-services-content>div>div:nth-child(2),
.cs-services-content>div>div:nth-child(5),
.cs-services-content>div>div:nth-child(8) { background-color: #f2f2f2 !important; }
.cs-services-content>div>div:not(:last-child) p:first-of-type { color: #7f7f7f; }
.cs-services-content>div>div:not(:last-child) p span { color: #EF233C; padding: 2px; border: 1px solid #EF233C; border-radius: 2px; margin: 12px; }
.cs-services-content>div>div:not(:last-child) p:last-of-type { color: #EF233C; font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑', sans-serif; font-size: 1.2em; }
// .cs-services-content>div>div:not(:last-child) a { width: 80%; border-radius: 6px; color: white; background-color: #EF233C; padding: 8px 20px; display: block; margin: 0 auto; } 
.cs-services-content>div>div:not(:last-child) .ant-btn { width: 80%; border-radius: 6px; color: white; background-color: #EF233C;  } 
.cs-services-content>div>div:last-child p { color: #EF233C; margin-top: 8px; }
/*资讯中心*/
.cs-news-box { margin: 25px auto; background-color: #f8f7f1; padding-top: 25px; padding-bottom: 25px; }
.cs-news-left { height: 300px; }
.cs-news-left-3 { height: 30px; position: absolute; bottom: 0; }
.cs-news-left-3 p, .cs-news-left-3 .more-icon { line-height: 30px; cursor: pointer; }
.cs-news-right p { line-height: 30px; color: #ccc; margin-top: 15px; margin-bottom: unset; }
.cs-news-right a { display: block; line-height: 30px; }

@media screen and (max-width: 970px) {
}
@media screen and (min-width: 971px) {
    .cs-top-box{ height: 60vh; }
}
@link-color: #333;@primary-color: #EF233C;