/*资讯内容*/
.news-content{ padding-bottom: 15px; }
.news-left-box { border: none; border-radius: 6px; -moz-box-shadow: 0 0 10px rgba(170, 170, 170, 0.34); -webkit-box-shadow: 0 0 10px rgba(170, 170, 170, 0.34); box-shadow: 0 0 10px rgba(170, 170, 170, 0.34); margin-bottom: 15px;}
.news-nav { border-bottom: 1px solid #EF233C; overflow-x: auto; }
.news-nav::-webkit-scrollbar { display: none; }
.news-nav a { padding: 10px 16px; border: 1px solid #f2f2f2; display: block; margin-right: -1px; color: #8C8C8C; box-sizing: border-box; min-width: 90px; }
.news-nav .news-this { background-color: #EF233C; color: white; border-color: #EF233C; }
.news-item { border-bottom: 1px dashed #d2d2d2; padding: 15px 8px; }
.news-left { height: 130px; }
.news-left h2 { font-size: 1.2em; line-height: 26px; }
.news-left p { color: #7f7f7f; margin: 8px auto; line-height: 26px; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 3; overflow: hidden; }
.news-right span { display: block; margin: 0 auto; }
.news-right { height: 130px; }
.news-right p { text-align: center; line-height: 26px; position: absolute; bottom: 0; left: 0; right: 0; margin: 0 auto 8px; color: #7f7f7f; }
/*右侧导航*/
@media screen and (max-width: 970px) {}
@media screen and (min-width: 971px) {
    .news-right-box { padding-left: 15px; }
}
.news-right-box .ant-card { margin: 0 auto; -moz-box-shadow: 0px 0px 10px rgba(170, 170, 170, 0.34); -webkit-box-shadow: 0px 0px 10px rgba(170, 170, 170, 0.34); box-shadow: 0px 0px 10px rgba(170, 170, 170, 0.34); } 
.news-right-box .ant-card .ant-card-head{ min-height: unset; }
.news-right-box .ant-card .ant-card-head .ant-card-head-wrapper { position: relative; height: 42px; line-height: 42px; color: #333; border-radius: 6px 6px 0 0; font-size: 14px; }
.news-right-box .ant-card .ant-card-body{ padding: 10px 15px; }
.news-right-img img { width: 100%; display: block; padding: 4px; border: 1px solid #f0f0f0; box-sizing: border-box; border-radius: 6px; box-shadow: 0px 0px 10px rgb(170 170 170 / 34%);}
.news-right-hot a { display: block; height: 32px; color: #8C8C8C; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; }
/*.news-right1 a:first-of-type { margin: 8px auto; border-bottom: 1px dashed #d2d2d2; }
.news-right1 a:not(:first-of-type) { color: #8C8C8C; }
.news-right2 img { max-width: 100%; }*/
.news-right-hot-tag a { padding: 6px; border: 1px solid #d2d2d2; display: inline-block; border-radius: 5px; color: #333; }
/* 分页 */
.news-page{ padding: 16px; text-align: center; }
/* 资讯详情 */
#news-info-box{ padding: 16px 8px; }
.news-info-box-content img{ max-width: 100%; }
@link-color: #333;@primary-color: #EF233C;