.nav-top-img { min-height: 80px !important; background-repeat: no-repeat; background-position: center; margin-bottom: 8px; }
/*今日推荐*/
.site-tj-div { background-color: #f1f1f1; padding: 0 15px; }
.site-tj-left { background-repeat: no-repeat; min-height: 80px; padding-top: 15px; }
.site-tj-left p { color: #EF233C; width: 45px; text-align: center; margin-bottom: unset; }
.site-tj-content { padding: 10px 0; }
.site-tj-item { background-color: #FFF; padding: 10px; border-radius: 6px; }
.site-tj-item-left { padding-right: 5px; height: 100px;  }
.site-tj-item-left img { width: 100%; height: 95px; border-radius: 4px; object-fit: cover; }
.site-tj-item-right p:first-child,.site-tj-item-right a:first-child { display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; overflow: hidden; line-height: 24px; height: 48px; }
.site-tj-item-right p { color: #666666; margin-bottom: 5px; }
.site-tj-item-btn { clear: both; text-align: center; }
.site-tj-item-btn a { margin: 0 auto; padding: 2px 10px; border: 1px solid #dddddd; border-radius: 5px; background-color: #f7f7f7; color: #666666; }
.site-tj-right { float: left; text-align: center; padding: 10px; }
.site-tj-right a { color: #EF233C; background-color: #f7f7f7; border: 1px solid #dddddd; border-radius: 5px; padding: 2px 15px; margin: 10px auto; display: block; width: 80%; }

/*网站筛选*/
.site-filter-div { margin: 16px auto; padding: 0 !important; }
.site-filter-tb,.site-filter-more { background-color: #f1f1f1; border-top: 1px solid #dddddd; border-bottom: 1px solid #dddddd; overflow-x: auto; padding: 8px 8px; }
.site-filter-tb-row{ text-align: center; display: block; background-color: #edf2f4; overflow-x: auto; }
.site-filter-items { border-bottom: 1px dashed rgb(215, 215, 215); padding: 8px 8px; }
.site-filter-items .ant-input-group{width: unset;}
.site-filter-items-select{padding: 8px;}
.site-filter-items-select .ant-select{ width:130px;margin-right:16px; }
.site-filter-item { padding: 2px; border: 1px solid #ef233c !important; color: #ef233c !important; border-radius: 3px; margin: 0 8px; }
.site-filter-this { color: white !important; background-color: #ef233c !important; border-radius: 3px; padding: 2px 4px; }
.site-filter-div .site-filter-items span,.site-filter-div .site-filter-tb span { line-height: 24px; margin-right: 16px; color: #333333; cursor: pointer; white-space: nowrap; display: inline-block !important; }
.site-filter-btn-left span { background-color: #FFF; border: 1px solid #dddddd; color: #333333; padding: 0 8px; margin: 0 -1px 0 0; float: left; width: 80px; text-align: center; cursor: pointer; }
.site-filter-tb::-webkit-scrollbar { display: none; }

/* .services-content-row::-webkit-scrollbar { display: none; } */

/*网站显示列表*/
.site-show { margin-top: 8px; }
.site-show-item { margin-bottom: 24px; padding: 12px; border-width: 0px; background: inherit; background-color: white; border: none; border-radius: 6px; -moz-box-shadow: 0px 0px 6px 2px rgba(226, 226, 226, 0.68); -webkit-box-shadow: 0px 0px 6px 2px rgba(226, 226, 226, 0.68); box-shadow: 0px 0px 6px 2px rgba(226, 226, 226, 0.68);  }
.site-show-img{ padding-right: 10px; max-height: 100px; }
.site-show-img img { width: 100%; height: 90px; object-fit: cover; border-radius: 5px; border: 1px solid #eeeeee; padding: 2px; box-sizing: border-box; }
.site-show-item-title-h3 { line-height: 1.5em; padding: 2px; word-break: break-all; }
.site-show-item-title-span { padding: 2px; border: 1px solid #ef233c !important; color: #ef233c !important; border-radius: 3px; }
.site-show-item p:first-child { color: #AAAAAA; margin-bottom: 6px; }
.site-show-item p:nth-child(2) { line-height: 20px; margin-bottom: unset; }
.site-show-btn { text-align: right; }
.site-show-btn a,.site-show-btn span { border-radius: 3px; padding: 5px 8px; width: 30%; display: inline-block; box-sizing: border-box; text-align: center; overflow: hidden; white-space: nowrap; cursor: pointer; }
.site-show-btn a:nth-child(1) { border: 1px solid #d7d7d7; color: #AAAAAA; }
.site-show-btn span:nth-child(2) { border: 1px solid #EF233C; color: #EF233C; margin: 0 5px; }
.site-show-btn a:nth-child(3) { color: #01AAED; border: 1px solid #01AAED; }
/* 详情页 */
.site-show-main-img { width: 100%; height: 100%;  padding: 2px; box-sizing: border-box; text-align: center;}
.site-show-main-img img{ border-radius: 5px; border: 1px solid #eeeeee; padding: 8px; max-width: 280px; max-height: 280px }
.site-info-imgs{ text-align: center; display: block; }
.site-info-promise{
  span { color: gray; }
  svg { margin-right: 4px }
}
.site-info-server-img{ padding: 6px; border-radius: 100%; width: 80%; margin: 0 auto; display: block; }
.site-info-server-chat{ align-self: center; }
/*联系客服*/
.site-chat { background-color: #f0f0f0; box-sizing: border-box; border-width: 1px; border-style: solid; border-color: rgb(221, 221, 221); border-radius: 0px; -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none; margin-top: 25px; padding: 10px !important; }
.site-chat p { margin-bottom: unset; padding: 4px; }
.site-chat a, .site-chat span { white-space: nowrap; margin-right: 20px; }
.site-chat p, .site-chat a { color: #aaaaaa; }
.site-chat p span:nth-child(1) { color: #333333; margin-right: 20px; }
.site-chat p span:nth-child(2) { color: #EF233C; margin-right: 20px; }
.site-chat p span i { color: #F59A23; }
/* 分页 */
.site-page{ text-align: center; }

/* antd重写 */
.ant-carousel .slick-prev, .ant-carousel .slick-prev:hover, .ant-carousel .slick-prev:focus { font-size: xx-large; left: 10px; z-index: 2; color: #EF233C; }
.ant-carousel .slick-next, .ant-carousel .slick-next:hover, .ant-carousel .slick-next:focus { font-size: xx-large; right: 10px; z-index: 2; color: #EF233C; } 

@link-color: #333;@primary-color: #EF233C;