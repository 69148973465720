@media screen and (max-width: 1200px) {
    .user-order-grid-first{ width: 100%; text-align: left; } 
    .user-order-grid{ display: none; }
}
@media screen and (min-width: 1201px) {
    .user-order-grid-first{ width: 55%; text-align: left; } 
    .user-order-grid{ width: 15%; text-align: center; }
}
.ant-menu-sub.ant-menu-inline { background-color: unset; }

.user-box{ margin-bottom: -25px; }
.user-order-pay-type{ display: block; margin: 0 8px; margin-top: 5px; padding-top: 1px; border-top: solid 1px #E5E5E5; }
#user-info-nav-btn {
    padding: 0 8px; margin-bottom: 32px;
    a{ color: white; padding: 8px; }
}
.user-box-right { background-color: white; }
@link-color: #333;@primary-color: #EF233C;