/*首屏 */
.vouch-top-box { background-image: url(../../../public/img/vouch/bg.jpg); background-repeat: no-repeat; background-size: cover; background-position: center;  }
.vouch-top-box-title { text-align: center; color: #EF233C; }
.vouch-top-box-title h2 { color: #EF233C; padding-top: 5%; text-align: center; letter-spacing: 0.2em; font-size: 2.6em; font-weight: bold; white-space: nowrap; overflow-x: auto; text-shadow: 1px 1px 2px #2c2c2c; }
.vouch-top-box-title p { line-height: 35px; padding-top: 5%; font-size: 1.4em; margin-bottom: unset; letter-spacing: 0.5rem;}
.vouch-top-box-title p span { font-size: 2.0em; }
.vouch-top-box-form { margin: 5% auto; }
.vouch-top-box-form .ant-form-item-explain-error{ text-align: center; }
.vouch-top-box-button { text-align: center; color: #EF233C; font-size: 1.2em; letter-spacing: 0.2em; margin: 50px auto; display: block; }
/*担保内容*/
.vouch-box { margin: 25px auto; padding: 15px; }
.vouch-box-content div div { text-align: center; background: linear-gradient(top, white, #f3f3f3); background: -ms-linear-gradient(top, white, #f3f3f3); background: -webkit-linear-gradient(top, white, #f3f3f3); background: -moz-linear-gradient(top, white, #f3f3f3); width: 90%; padding: 8px; box-sizing: border-box; margin: 0 auto; }
.vouch-box-content div h2 { font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑', sans-serif; font-weight: 700; font-style: normal; font-size: 16px; letter-spacing: 5px; line-height: 30px; }
.vouch-box-content div p { line-height: 26px; text-align: center; overflow: hidden; margin-bottom: unset; }
.vouch-box .vouch-box-btn { display: block; border-radius: 5px; background-color: #EF233C; border-width: 0; color: white; padding: 8px 20px; margin: 8px auto; letter-spacing: 0.4em; cursor: pointer; }
/*交易流程*/
.vouch-process-box { margin: 25px auto; padding: 15px; }
/*最新委托*/
.vouch-order { margin: 25px auto; padding: 15px; }
.vouch-order-item div { margin-top: 10px; }
.vouch-order-item div span { color: #EF233C; }
.vouch-order-item div p { color: #aaaaaa; text-align: left; }

@media screen and (max-width: 970px) {
}
@media screen and (min-width: 971px) {
    .vouch-top-box{ height: 60vh; }
}
@link-color: #333;@primary-color: #EF233C;