/*首屏*/
.brand-top-box { background-image: url(../../../public/img/brand/u1.jpg); background-size: cover; background-position: bottom; background-repeat: no-repeat; }
.brand-top-box-title { color: white; text-align: center; }
.brand-top-box-title h2 { font-size: 2.6em; padding-top: 6%; letter-spacing: 0.2em; padding-bottom: 2%; font-weight: bold; white-space: nowrap; color: white; text-shadow: 1px 1px 2px #2c2c2c; }
.brand-top-box-title img { vertical-align: baseline; width: 48px; height: 48px; }
.brand-top-box-title p { font-size: 1.2em; line-height: 40px; letter-spacing: 0.5rem; }
// .brand-top-box-div { margin: 70px auto 150px auto; padding: 40px 50px; box-sizing: border-box; border-radius: 5px; background-color: rgba(255, 255, 255, 1); border: none; border-radius: 5px; -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.34); -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.34); box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.34); overflow-x: auto; }
.brand-top-box-div { margin: 45px auto 50px auto; padding: 40px 50px; box-sizing: border-box; border-radius: 5px; background-color: rgba(255, 255, 255, 1); border: none; border-radius: 5px; -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.34); -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.34); box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.34); overflow-x: auto; }
.brand-top-box-div::-webkit-scrollbar { display: none; }
.brand-top-box-div-li { text-align: center; }
.brand-top-box-div-li p { line-height: 20px; color: #555555;  }
.brand-top-box-div-li p i { color: #EF233C; font-size: 12px; line-height: 20px; display: inline-block; }
.brand-top-box-div-span { color: #7F7F7F; }
.brand-top-box-div-span p { line-height: 20px; margin-bottom: unset; }
.brand-top-box-div-span img { vertical-align: text-top; margin-right: 4px; }
/*商标注册*/
.brand-mark-box { margin: 25px auto; }
// .brand-mark-item { border: 1px solid #EF233C; box-sizing: border-box; width: 80%; border-radius: 4px; background-color: white; -moz-box-shadow: 0px 0px 10px rgba(215, 215, 215, 0.9); -webkit-box-shadow: 0px 0px 10px rgba(215, 215, 215, 0.9); box-shadow: 0px 0px 10px rgba(215, 215, 215, 0.9); }
.brand-mark-item { display: flex; justify-content: center; flex-direction: column; text-align: center; padding: 26px 18px; border-radius: 6px;  background-color: white; box-shadow: 0px 0px 10px rgba(215, 215, 215, 0.9);}
.brand-mark-item>.brand-mark-p{ font-size: 26px; font-weight: 600;  margin-bottom: 8px; color:#1f1f1f; }
.brand-mark-item>.brand-mark-p>span{ font-size: 16px; color: #bfbfbf; font-weight: normal; }
.brand-mark-item>.brand-mark-p>span:first-child{ margin-top: -25px; }
.brand-mark-item>.brand-mark-p>span:last-child{ font-size: 16px; color: #bfbfbf; font-weight: normal; }
.brand-mark-item>.brand-mark-t{ font-size: 20px; font-weight: 600;  color:#1f1f1f; margin-bottom: 8px; }
.brand-mark-item>.brand-mark-b{ text-align: left; padding: 12px 6px; color: #8c8c8c; }
.brand-mark-item>.brand-mark-b>p>span{ margin-right: 4px; }
// .brand-mark-t { text-align: center; padding: 8px; color: #EF233C; font-size: 18px; letter-spacing: 0.5em; }
// .brand-mark-m { text-align: center; padding: 20px 8px; border-top: 1px solid rgb(215, 215, 215); border-bottom: 1px solid rgb(215, 215, 215); }
// .brand-mark-m p { color: #EF233C; font-size: 18px; font-weight: bold; }
// .brand-mark-m button { display: block; border: 1px solid #EF233C; width: 40%; margin: 5px auto; padding: 3px; border-radius: 4px; }
// .brand-mark-b { padding: 8px; line-height: 28px; color: #333333; }
// .brand-mark-b p { margin-bottom: unset; }
/*所需材料*/
.brand-need-box { background-color: #f8f7f1; padding: 25px 15px; }
.brand-need-box-content p { line-height: 35px; }
/*服务流程*/
.brand-process-box { margin: 25px auto; }
// .brand-process-content { /*background-image: url(../images/brand/p-b.png); background-repeat: no-repeat; background-size: cover; background-position: center; text-align: center; border-radius: 4px; min-height: 315px; margin-top: 20px;*/ }
.brand-process-content img { margin-top: 10px; width: 100%; }
.brand-process-content p { color: white; line-height: 35px; font-weight: bold; margin-bottom: 15px; }
/*常见问题*/
.brand-problem-conent div p { line-height: 26px; }
.brand-problem-conent div p:first-child { border-bottom: 1px dashed #ccc; }
.brand-problem-conent div p:last-child { color: #aaaaaa; }
.brand-problem-conent div p img { margin-right: 4px; }

@media screen and (max-width: 970px) {
    .brand-top-box{ height: 25vh; }
}
@media screen and (min-width: 971px) {
    .brand-top-box{ height: 60vh; min-height: 550px; }
}
@link-color: #333;@primary-color: #EF233C;