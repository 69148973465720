.comm-red { color: #EF233C !important; }
.more { cursor: pointer; }
.more-icon { color: #EF233C; display: inline-block; }
.common-elip { text-overflow: ellipsis; overflow: hidden; white-space: nowrap; }
.common-elip-2 { overflow: hidden; text-overflow: ellipsis; width: 100%; display: block; -webkit-box-orient: vertical; -webkit-line-clamp: 2; word-break: break-all; white-space: nowrap; }
.common-elip-3 { overflow: hidden; text-overflow: ellipsis; width: 100%; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 3; word-break: break-all; } 
/* 浮动 */ 
.fl{ float: left; }
.fr{ float: right; }
/* 字体方向 */
.t_left{ text-align: left; }
.t_right{ text-align: right; }
.t_center{ text-align: center;}
/*导航*/
.nav-this { color: #EF233C !important; }
/*模块标题*/
.nav-title h2 { font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑', sans-serif; font-weight: 700; margin-bottom: 4px; letter-spacing: 0.2em; }
.ctn-box { padding-left: 8px; padding-right: 8px; }
@link-color: #333;@primary-color: #EF233C;