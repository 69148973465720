
// Head
@media screen and (max-width: 970px) {
    .top-nav-right { text-align: left; }
}
@media screen and (min-width: 971px) {
    .top-nav-right { text-align: right; }
}
.head-top-nav-box { border-bottom: 1px solid #ef233c; padding: 15px 8px; background-color: white; }
.head-top-nav-left img { width: 60px; height: 60px; float: left; margin-right: 8px; }
.head-top-nav-left a { display: inline-block; }
.head-top-nav-left a span { line-height: 30px; color: #333333; font-size: 18px; display: inline-block; }
.head-top-nav-right a { display: inline-block; line-height: 30px; color: #333333; margin-right: 18px; font-size: 18px; }
.head-top-nav-box .head-nav-icon { color: #ef233c; font-size: 22px; cursor: pointer; display: block; text-align: right; }

/* 导航-首页 */
.head-top-nav-box-index { 
    padding: 15px 8px; border-bottom: 1px solid rgba(255, 255, 255, 0.4); 
    a,span { color: white; }
}
.head-top-nav-box-index .head-top-nav-left span { color: white; }
.head-top-nav-box-index .head-nav-icon { color: white;  }
// Footer
.footer { margin-top: 25px; margin-bottom: 3px; border-top: 1px solid red; border-bottom: 1px solid red; background-color: white; }
.footer-left { padding: 25px 0; }
.footer-left div a, .footer-left div p { line-height: 35px; color: #888888; display: inline-block; height: 35px; margin: 0; cursor: pointer;}
.footer-left div p a { padding-left: 1em; padding-right: 15px; }
.footer-weix { height: 18px; width: 18px; margin-left: 2px; margin-top: -2px; }
.footer-right { cursor: pointer; text-align: right; padding-right: 10px; }
.footer-right span { color: #888888; line-height: 155px; }
.footer-right .iconfont { color: red; }
/*你的位置*/
.nav-log p { line-height: 36px; font-size: 1.1em; color: #7F7F7F; margin-bottom: unset; }
.nav-log p a { color: #7F7F7F; }

/*颜色*/
.hue-blue{ color: #1E9FFF !important; }
.hue-danger{ color: #FF5722 !important; }
.hue-warning{ color: #FFB800 !important; }
.hue-gray{ color:#c2c2c2 !important; }
.hue-main{ color:#EF233C !important; }

.hue-bg-blue{ background-color: #1E9FFF !important; }
.hue-bg-danger{ background-color: #FF5722 !important; }
.hue-bg-warning{ background-color: #FFB800 !important; }
.hue-bg-gray{ background-color:#c2c2c2 !important; }
.hue-bg-main{ background-color:#EF233C !important; }
.hue-bg-white{ background-color: white; }

.hue-con-box{ padding: 8px 0;}

// 抽屉导航
.drawer-menu{
    .ant-drawer-content{ transition: transform 250ms ease-in-out; background-color: #fffffc;}//background: linear-gradient(180deg, #FC466B 0%, #3F5EFB 100%);
    .ant-drawer-body a{ 
        display: block; width: 100%; text-transform: uppercase; font-weight: bold; text-decoration: none;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: bold;
        padding: 15px;
        color:#424242;
        cursor: pointer;
        border-bottom: 1px solid rgba(255, 255, 255, 0.10);
        span { padding:0 4px; }
    }
    .user-info{ padding: 6px; border-bottom: 1px solid #EF233C; text-align: center;}
}
@link-color: #333;@primary-color: #EF233C;