.trade_box {
    padding: 8px;
    margin: 25px auto;
}

.trade_box p {
    margin-bottom: unset;
}

.trade_item_sub{
    padding: 0 8px;
}
.trade_item_pay {
    background-color: #f4f4f4;
    margin: 24px auto;
    padding-top: 24px;
    padding-bottom: 24px;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.trade_item_money{
    color: #EF233C;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.5;
}
@link-color: #333;@primary-color: #EF233C;