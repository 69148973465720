/*媒体查询css*/
@media screen and (max-width: 970px) {
	.business-nav .ant-row .ant-col:nth-child(2n-1) { border-right: 1px solid #ccc; }
}

@media screen and (min-width: 971px) {
	.business-nav .ant-row .ant-col:not(:last-child) { border-right: 1px solid #ccc; }
}

.more { cursor: pointer; text-align: right; color: #333; float: right; }
.more:hover { color: #777; }
.more-icon { color: #EF233C; display: inline-block; }

/* 首屏 */
.home-video-box {
	position: relative; top: 0; left: 0; z-index: 0; width: 100%; background-position: center; background-repeat: no-repeat; background-size: cover; transition: background-image 1s ease 0.5s;
	video { position: absolute; z-index: -999; height: 100%; width: 100%; top: 0; left: 0; object-fit: cover; visibility: inherit; }
	.home-top-box{ height: 60vh; min-height: 400px; }
}
.home-top-content { padding-top: 15px; overflow: hidden; }

.home-top-content  {
	p,h1 { text-align: center; color: #ffffff; cursor: default; } 
	h1 { font-weight: 400; letter-spacing: 4px; font-size: 62px;}
	p { color: #f0f0f0; font-size: 2em; margin-top: 20px; letter-spacing: 2px; }
}

.home-top-trading { background-color: white; box-shadow: 0px 3px 4px 2px #efefef; z-index: -1; padding-top: 4px; padding-bottom: 4px; }
.home-top-trading span, .home-top-trading p { line-height: 35px; letter-spacing: 1px; margin-top: unset; margin-bottom: unset; }
.home-top-trading-i { color: red !important; margin-right: 4px; display: inline-block; line-height: 35px; }
.home-top-trading p span:first-child { font-style: inherit; margin-right: 8px; color: #c2c2c2; }
.home-top-trading p span:not(:first-child) { color: red; font-weight: bold; }

/*业务导航*/
.home-bus { margin-top: 25px; padding: 15px; }
.home-bus-nav{
	a { 
		display: block; margin: 8px; padding: 16px; border-radius: 6px; box-shadow: 0px 0px 6px 0px #f0f0f0; border: 1px solid #f0f0f0; text-align: center; background-color: white;
		img { width: 36px; margin-bottom: 1em; }
		p { line-height: 2rem; } 
		span { font-size: 32px; color: #EF233C; }
	}
}

.home-bus-nav a>div>p:first-child { font-size: 16px; }
.home-bus-nav a>div>p:last-child { color: #8c8c8c; }
/* 网站交易 */
.home-site { margin-top: 25px; padding: 15px; }
.home-site-list .hue-bg-white { 
	margin: 8px; padding: 12px 8px; border-radius: 6px; box-shadow: 0px 0px 6px 0px #f0f0f0; border: 1px solid #f0f0f0;
	img { width: 120px; height: 120px; margin: 0 auto; display: block; border: 1px solid #fc4e5d; padding: 2px; border-radius: 4px; }
	p { text-align: center; }
	.home-site-title { margin-top: 15px; font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑', sans-serif; font-weight: 700; font-style: normal; font-size: 16px; color: #595959; margin-bottom: unset; }
	a { 
		display: block; margin: 0 auto; width: 40%; height: 38px; line-height: 38px; text-align: center; color: #8C8C8C; border: 1px solid #797979; border-radius: 6px;
		&:hover{ color: #EF233C; border-color: #EF233C; }
	}
	.home-site-info { margin: 8px 0; font-family: 'Arial Normal', 'Arial', sans-serif; font-weight: 400; font-style: normal; font-size: 14px; color: #8C8C8C; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; overflow: hidden; line-height: 24px; height: 48px; }
}

/* 资讯中心 */
.home-news-box { 
	margin-top: 25px; background-color: #f8f7f1; padding: 25px 15px; 
	.home-news-left { 
		padding-left: 20px; 
		h3 { font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑', sans-serif; font-weight: 700; font-style: normal; font-size: 16px; }
		a { margin-right: 4px; &:hover{ color: #EF233C; }}
	}
	.home-news-right {
		p { line-height: 30px; color: #ccc; margin-top: 6px; margin-bottom: unset; }
		a { display: block; line-height: 30px; &:hover{ color: #EF233C; } }
	}
}

/* 关于我们 */
.home-about { margin-top: 25px; padding: 15px; }
.home-about-content { 
	box-shadow: 0px 0px 6px 0px #f0f0f0; border: 1px solid #f0f0f0; border-radius: 6px; display: block; background-color: white;
	img { width: 100%; height: 180px; border-top-left-radius: 8px; border-top-right-radius: 8px; }
	.home-about-title { padding: 10px 10px; font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑', sans-serif; font-weight: 700; font-style: normal; font-size: 16px; color: #8C8C8C; margin-bottom: unset; }
	.home-about-info { margin: 0 10px 5px; font-family: 'Arial Normal', 'Arial', sans-serif; font-weight: 400; font-style: normal; font-size: 14px; color: #8C8C8C; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; overflow: hidden; }
}
@link-color: #333;@primary-color: #EF233C;